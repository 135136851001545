import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
export default function Confirm(props) {
  return (
    <Modal
      show={props.show}
      centered
      style={{ zIndex: "20000" }}
      backdropClassName="z-index-top"
    >
      <Modal.Header>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn"
          onClick={() => props.onClose()}
        >
          Batal
        </button>
        <button
          className="btn btn-danger"
          onClick={() => props.onChange(true)}
        >
          {props.btnConfirmText}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
