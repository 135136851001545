import React, { Component, Fragment } from "react";
import { isMobile, browserName } from "react-device-detect";

import ContentHeader from "../../components/mikro/ContentHeader";

//// MODEL
import { toast } from "react-toastify";
import { getIndex, updateItem } from "./../../utils/api";
import { getUser } from "../../utils/AuthUser";
import toastr from "../../utils/toastr";
import { EyeIcon, EyeSlashIcon } from "../../components/mikro/Icon";

export default class Profil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nama_pic: "",
      nomor_hp: "",
      email: "",
      spinner: false,
      showPassword: false,
    };
  }

  componentDidMount() {
    const user = getUser();
    getIndex("profile").then((res) => {
      this.setState({
        nama_pic: res.data.nama_pic,
        email: res.data.email,
        nomor_hp: res.data.nomor_hp,
      });
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  fStore(e) {
    e.preventDefault();
    let data = {
      nama_pic: this.state.nama_pic,
      email: this.state.email,
    };

    updateItem('profile',data).then((res) => {
      toastr(res.status,res.message)
    });
  }

  fChangePassword(e) {
    let data = {
      current_password: this.state.current_password,
      password: this.state.password,
      confirm: this.state.confirm,
    };

    if (data.password !== data.confirm)
      toast.warn("Password Baru dan Ulangi Password tidak sama");
    else
      updateItem('password-reset',data).then((res) => {
        toastr(res.status,res.message)

        this.setState({
          current_password: "",
          password: "",
          confirm: "",
        });
      });
  }
  render() {
    return (
      <Fragment>
        <ContentHeader
          title="Profil"
        />
        <div className="row">
          <div className={!isMobile ? "col-md-6 pr-2" : " col-md-6"}>
            <div className="content-body">
              <div className="row">
                <div className="col-12 mb-1">
                  <div className="form-group text-bold-500 text-left">
                    Profil PIC
                    <hr className="mt-1 mb-2" />
                  </div>
                  <div className="row">
                    <div className="col-sm-12 mb-2">
                      <label>Nama</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nama"
                        name="nama_pic"
                        value={this.state.nama_pic}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 mb-2">
                      <label>Nomor HP</label>
                      <input
                        className="form-control"
                        placeholder="0823*****"
                        value={this.state.nomor_hp}
                        readOnly={true}
                      />
                    </div>
                    <div className="col-sm-6 mb-2">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={(e) => this.handleChange(e)}
                      />
                    </div>
                  </div>

                </div>
              </div>

              <div className="row p-1">
                <div className="col-12">
                  <div className="form-group text-right">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={(e) => this.fStore(e)}
                    >
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={!isMobile ? "col-md-6 pl-2" : "col-md-6"}>
            <div className="row">
              <div className="col-12 mb-1">
                <div className="form-group text-bold-500 text-left">
                  Ganti Password
                  <hr className="mt-1 mb-2" />
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-2">
                    <label>Password Lama</label>
                    <div class="input-group m-0">
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        className="form-control px-2 form-control-auth"
                        name="current_password"
                        minLength="6"
                        maxLength="50"
                        placeholder="Password Lama"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.current_password}
                        autoComplete="off"
                        required
                        disabled={this.state.spinner}
                      />
                      <div
                        class="input-group-append bg-white pointer"
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                      >
                        <span class="input-group-text bg-white fs-16px  px-1-2">
                          {this.state.showPassword ? (
                            <EyeIcon />
                          ) : (
                            <EyeSlashIcon/>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-2">
                    <label>Password Baru</label>
                    <div class="input-group m-0">
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        className="form-control px-2 form-control-auth"
                        name="password"
                        minLength="6"
                        maxLength="50"
                        placeholder="Password Baru"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.password}
                        autoComplete="off"
                        required
                        disabled={this.state.spinner}
                      />
                      <div
                        class="input-group-append bg-white pointer"
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                      >
                        <span class="input-group-text bg-white fs-16px  px-1-2">
                          {this.state.showPassword ? (
                            <EyeIcon />
                          ) : (
                            <EyeSlashIcon/>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2">
                    <label>Ulangi Password Baru</label>
                    <div class="input-group m-0">
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        className="form-control px-2 form-control-auth"
                        name="confirm"
                        minLength="6"
                        maxLength="50"
                        placeholder="Ulangi Password Baru"
                        onChange={(e) => this.handleChange(e)}
                        value={this.state.confirm}
                        autoComplete="off"
                        required
                        disabled={this.state.spinner}
                      />
                      <div
                        class="input-group-append bg-white pointer"
                        onClick={() =>
                          this.setState({
                            showPassword: !this.state.showPassword,
                          })
                        }
                      >
                        <span class="input-group-text bg-white fs-16px  px-1-2">
                          {this.state.showPassword ? (
                            <EyeIcon />
                          ) : (
                            <EyeSlashIcon/>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="form-group text-right">
                      <button
                        type="button"
                        className="btn btn-info"
                        onClick={(e) => this.fChangePassword(e)}
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
