import React, { Fragment, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import {deleteSaveUser,getUser} from "./../utils/AuthUser";
import logo from "./../assets/logo/logo-white.png";
import userIcon from "./../assets/icon-user.png";
import { BurgerIcon, TagihanOutletIcon } from "../components/mikro/Icon";

const Navbar = props => {
  
  const [toLogin,setToLogin] = useState(false);
  const [namaUser,set_namaUser] = useState("");
  const [nama_perusahaan,set_nama_perusahaan] = useState("");
  
  useEffect(()=>{
    const user = getUser();
    set_namaUser(user.nama_pic)
    set_nama_perusahaan(user.nama_perusahaan)
  },[])

  const handleSidebar = () => {
    props.onChange(!props.show);
  }

  const handleLogout = () => {
    deleteSaveUser();
    setToLogin(true);
  }

  return (
    <Fragment>
      {toLogin ? <Redirect to="/" /> : ""}
      <nav className="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-dark navbar-brand-center"
        // style={{ borderBottom:"1px #eceff1 solid" }}
      >
        <div className="navbar-wrapper">
          <div className="navbar-header">
            <ul className="nav navbar-nav flex-row">
              <li className="nav-item  mobile-menu d-md-none mr-auto">
                <Link
                  className="nav-link nav-menu-main menu-toggle hidden-xs p-2"
                  to="#"
                  onClick={() => handleSidebar()}
                >
                  <BurgerIcon width="24" height="24"/>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="navbar-brand" to="/app/beranda">
                  <img className="brand-logo" alt="MySponsorID" src={logo} />
                </Link>
              </li>
              <li className="nav-item mobile-menu d-md-none p-2">
                <Link
                  className="dropdown-toggle nav-link text-right py-0"
                  to="#"
                  data-toggle="dropdown"
                >
                  <span className="avatar avatar-online">
                    <img
                      src={userIcon}
                      alt="avatar"
                      style={{ verticalAlign: "unset" }}
                    />
                  </span>
                </Link>
                <div className="dropdown-menu dropdown-menu-right">
                  <Link
                    className="dropdown-item"
                    to={props.url + "/profil-pic"}
                  >
                    <i className="ft-user"></i> Profile
                  </Link>
                  <div className="dropdown-divider"></div>
                  <div
                    className="dropdown-item pointer"
                    onClick={() => handleLogout()}
                  >
                    <i className="ft-power"></i> Logout
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="navbar-container content">
            <div className="collapse navbar-collapse" id="navbar-mobile">
              <ul className="nav navbar-nav mr-auto float-left">
                <li className="nav-item dropdown dropdown-user  d-none d-md-block">
                  <Link
                    className="nav-link nav-menu-main menu-toggle hidden-xs"
                    to="#"
                    onClick={() => handleSidebar()}
                  >
                    <BurgerIcon width="24" height="24"/>
                  </Link>
                </li>
              </ul>
              <ul className="nav navbar-nav float-right">
                <li className="dropdown dropdown-user nav-item">
                  <Link
                    className="dropdown-toggle nav-link  py-0"
                    to="#"
                    data-toggle="dropdown"
                  >
                    <span
                      className="mr-1"
                      style={{ display: "inline-flex", lineHeight: "19px" }}
                    >
                      <span className="user-name text-bold-700">
                        {namaUser}
                        <br />
                        {nama_perusahaan != "" ? (
                          <span className="font-small-3">
                            {nama_perusahaan}
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                    </span>
                    <span className="avatar avatar-online">
                      <img
                        src={userIcon}
                        alt="avatar"
                        style={{ verticalAlign: "unset" }}
                      />
                    </span>
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      className="dropdown-item"
                      to={props.url + "/profil-pic"}
                    >
                      <div
                        style={{
                          paddingTop: "0.6rem",
                          paddingBottom: "0.6rem",
                        }}
                      >
                        <i className="ft-user"></i> Profil
                      </div>
                    </Link>
                    <div className="dropdown-divider"></div>
                    <div
                      className="dropdown-item pointer"
                      onClick={() => handleLogout()}
                    >
                      <div
                        style={{
                          paddingTop: "0.6rem",
                          paddingBottom: "0.6rem",
                        }}
                      >
                        <i className="ft-log-out"></i> Keluar
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </Fragment>
  );
}


export default Navbar;