import React, { Component } from "react";

//filter
import PencarianInput from "../../components/plugin/filter/PencarianInput";

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.props.onChangeValue(this.state)
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <PencarianInput
              name="search"
              onChangeValue={(e) => this.handleChange(e)}
            />
          </div>
        </div>
      </div>
    );
  }
}
