
import React, { Component, Fragment } from 'react';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import CardWidgetTotal from '../../components/mikro/CardWidgetTotal';
import { UploadIcon } from '../../components/mikro/Icon';
import { getIndex } from '../../utils/api';

//// MODEL

export default class Beranda extends Component {
  constructor(props) {
    super(props)
    this.state = {
      per_month: 0,
      per_year: 0,
      category: 0
    }
  }

  componentDidMount() {
    this.getData()
  }

  getData() {
    getIndex("widget-info").then((res) => {
      if(res.code === 200)
        this.setState({
            per_month: res && res.data && res.data.per_month || '0',
            per_year: res && res.data && res.data.per_year || '0',
            category: res && res.data && res.data.category || '0',
        })
    });
  }

  handleFilter(e) {
    
  }

  render() {
    return (
      <Fragment>
       
        <div className="content-body">
          <div className="row mb-2">
            <CardWidgetTotal title="Proposal Masuk Tahun Ini" color="info" total={this.state.per_year}/>
            <CardWidgetTotal title="Proposal Masuk Bulan Ini" color="warning" total={this.state.per_month}/>
            <CardWidgetTotal title="Kategori Ditandai" color="danger" total={this.state.category}/>
          </div>
          <div className='row mb-2'>
            <div className='col'>
              <div className='card border-left-blue border-right-blue'>
                <div className='card-header pb-0'>
                  <h4 className='font-weight-bold m-0'>Info</h4>
                </div>
                <div className='card-body'>
                  <p className='fs-16px'>Demi mengoptimalkan hubungan Sponsor dan Event Organizer, MySponsorID membutuhkan data minat Sponsor selaku penerima proposal yang diajukan oleh Event Organizer untuk menginput data Jenis Kegiatan/Event pada menu <Link to="/app/kategori">Kategori</Link>  di sidebar.</p>

                  <p className='fs-16px'>Anda juga dapat melengkapi data profil perusahaan untuk dapat ditampilkan pada kolom part di <a href="https://mysponsor.id">Landing Page MySponsor</a> .</p>
                 
                </div>
              </div>
            </div>
          </div>
          

        </div>
      </Fragment>
    );
  }
}

