import React, { Component, Fragment } from "react";
import BGImagePng from './../../../assets/upload-icon.png'
export default class ImageUploadPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      divStyle: {
        height: this.props.height || "125px",
        width: this.props.width || "140px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundColor: "#d3d3d3",
        backgroundImage: `url(${BGImagePng})`,
        backgroundRepeat: "no-repeat",
      },
      inputStyle: {
        opacity: "0.0",
        position: "absolute",
        top: "0",
        left: "0",
        bottom: "0",
        right: "0",
        width: "100%",
        height: "100%",
      },
    };
  }

  componentDidMount(prevProps) {
    if (this.props.value !== "") {
      let divStyle = { ...this.state.divStyle };
      divStyle.backgroundImage =
        this.props.value !== "" ? "url(" + (this.props.value || "") + ")" :`url(${BGImagePng})`;
      this.setState({ divStyle });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value && this.props.value !== "") {
      setTimeout(() => {
        let divStyle = { ...this.state.divStyle };
        divStyle.backgroundImage =
          this.props.value !== ""
            ? "url(" + (this.props.value || "") + ")"
            : `url(${BGImagePng})`;
        this.setState({ divStyle });
      }, 200);
    }
  }

  getInitials = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    if (initials.length === 1) {
      return string
        .substr(0, 2)
        .toUpperCase()
        .toLowerCase()
        .replace(/\b[a-z]/g, function (letter) {
          return letter.toUpperCase();
        });
    } else {
      return initials.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });
    }
  };

  handleChange(e) {
    e.preventDefault();
    if (e && e.target && e.target.files && e.target.files[0]) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      let divStyle = {
        ...this.state.divStyle,
        backgroundImage: "url(" + URL.createObjectURL(files[0]) + ")",
      };
      this.setState({ divStyle }, () => this.props.onChangeValue(files[0]));
    }
  }

  handleReset() {
    let divStyle = { ...this.state.divStyle };
    divStyle.backgroundImage = `url(${BGImagePng})`;
    this.setState(
      {
        divStyle: divStyle,
      },
      () => {
        this.props.onChangeValue("");
      }
    );
  }

  render() {
    return (
      <Fragment>
        <div
          style={this.state.divStyle}
          className={"card bg-grey pointer "+(this.props.isMarginNone ? "":"mx-auto")}
        >
          <input
            type="file"
            id="inImageForm"
            accept="image/png, image/jpeg, image/jpg"
            style={this.state.inputStyle}
            onChange={(e) => this.handleChange(e)}
            className="pointer"
          />
          {this.state.divStyle.backgroundImage !==
          `url(${process.env.PUBLIC_URL + "/img/upload-icon.png"})` ? (
            <div
              className="rounded-circle pointer"
              style={{
                position: "absolute",
                top: "-10px",
                right: "-10px",
                width: "20px",
                height: "20px",
                background: "red",
                color: "#fff",
                textAlign: "center",
                fontWeight: 600,
              }}
              onClick={() => this.handleReset()}
            >
              x
            </div>
          ) : (
            ""
          )}
        </div>
      </Fragment>
    );
  }
}
