import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-slide-out/lib/index.css";

import "./../assets/styles/app.css";
import "./../assets/styles/table-scroll.css";
import "./../assets/css/bootstrap.css";
import "./../assets/css/bootstrap-extended.css";
import "./../assets/css/colors.css";
import "./../assets/css/components.css";
import "./../styles/correction.css";
import "./../styles/properties.css";

import { BrowserRouter as Router, Route, Switch, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PrivateRoute } from "../middleware/PrivateRoute";
import { ToastContainer } from "react-toastify";

// import route Components here
import Masuk from "./Auth/Masuk";
import LupaPassword from "./Auth/LupaPassword";
import ResetPassword from "./Auth/ResetPassword";

import AuthRoute from "./AuthRoute";
import toastr from "../utils/toastr";
import Daftar from "./Auth/Daftar";

export default function App() {
  //SETTER
  const store = useSelector((state) => state);

  useEffect(() => {
    if (store.response.message && store.response.message !== "")
      toastr(store.response.status, store.response.message);
  }, [store]);

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Router>
        <Switch>
          <Route exact path="/" component={Masuk} />

          <Route path="/lupa-password" component={LupaPassword} />
          <Route path="/daftar" component={Daftar} />
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route
            path="/invalid-token"
            render={() => {
              return (
                <div className="my-5">
                  <h1 className="text-center mb-4">
                    Token Anda tidak valid{" "}
                  </h1>
                  <h1 className="text-center">
                    <Link className="btn btn-lg btn-warning" to="/">
                      Kembali Ke Halaman Utama
                    </Link>
                  </h1>
                </div>
              );
            }}
          />
          <PrivateRoute path="/app" component={AuthRoute} />
          <Route
            render={() => {
              return <h1>404 Not Found</h1>;
            }}
          />
        </Switch>
      </Router>
    </React.Fragment>
  );
}