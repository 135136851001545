import React from 'react';
import Empty from './../../assets/empty.png';
const EmptyPage = (props) => {
    
    return (
      <div className="row my-3">
        <div className="col text-center">
          <img className="img-fluid esponsive mb-1" width="150px" alt="Getkasir" src={Empty}/>
          <p style={{color: '#ccc', textAlign: 'center'}}>{ props.message || 'Data Tidak Tersedia' }</p>
        </div>
      </div>
    );
}
export default EmptyPage;