// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAIP2AB7ZbkquA8srEGc-73gjD14K_H_rI",
  authDomain: "getkasir-firebase.firebaseapp.com",
  databaseURL: "https://getkasir-firebase-default-rtdb.firebaseio.com",
  projectId: "getkasir-firebase",
  storageBucket: "getkasir-firebase.appspot.com",
  messagingSenderId: "474716612576",
  appId: "1:474716612576:web:1482a4599a557934282d85",
  measurementId: "G-Q5FZJBSL6R"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);