import React, { Component, Fragment } from "react";
import Helmet from "react-helmet";
import {  Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import userIcon from "./../../assets/icon-user.png";

import { screenOrientation } from "../../utils/DeviceDetector";
import AuthIlustration from "./../../components/mikro/AuthIlustration";
import LogoLink from "./../../components/mikro/LogoLink";
import { getNoAuth, postNoAuth } from "./../../utils/api";
import { EyeIcon, EyeSlashIcon } from "../../components/mikro/Icon";

export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecting: false,
      spinner: false,
      showPassword: false,

      token: "",
      name: "",
      password: "",
      confirmed: "",

      error_message: "",

      validity: 0,
    };
  }

  componentDidMount() {
    const { token } = this.props.match.params;

    getNoAuth("password/find/" + token).then((res) => {
      if (res.code === 200) {
        this.setState({
          name: res.data.name,
          token: res.data.token,
          validity: 1,
        });
      } else {
        if (res.message) toast.error(res.message);
        this.setState({
          redirecting: true,
        });
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let error_message = "";

    if (this.state.password.length < 6 || this.state.confirmed.length < 6) {
      error_message = "Password minimal 6 karakter";
    }

    if (this.state.password !== this.state.confirmed) {
      error_message = "Password dan ulangi password tidak sama";
    }

    if (error_message === "") {
      this.setState({ spinner: true });
      postNoAuth("password/reset", {
        password: this.state.password,
        token: this.state.token,
      }).then((res) => {
        if (res.code === 200) {
          toast.success("Selamat!, Password Anda telah diperbarui");
          setTimeout(() => {
            this.setState({
              redirecting: true,
            });
          }, 3000);
        } else {
          if (res.message) toast.error(res.message);
          this.setState({
            spinner: false,
          });
        }
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { name, password, confirmed, spinner, showPassword } = this.state;
    return (
      <Fragment>
        {this.state.redirecting ? <Redirect to="/" /> : ""}
        <Helmet
          bodyAttributes={{
            style: "padding-top: 0!important",
          }}
        />
        <div className="d-flex w-100" style={{ height: "100vH" }}>
          <div className="login-bg h-100">
              <AuthIlustration />
          </div>
          <div className="login-form bg-white overflow-auto m-0">
            <div
              className={
                " row h-100 w-100 py-2 m-0 align-items-center justify-content-center " +
                (screenOrientation() !== "portrait" ? " px-5" : "px-1")
              }
            >
              <div class="p-2 col-md-12 text-center">
                <LogoLink width="150px" />
              </div>
              <div class="p-2 col-md-12">
                <form className="form" onSubmit={(e) => this.handleSubmit(e)}>
                  <fieldset className="form-group position-relative has-icon-left mb-3">
                    <div class="text-center  mb-2">
                      <img
                        src={userIcon}
                        class="rounded-circle img-float img-border height-100"
                        alt={name}
                      />
                    </div>
                    <h6 className="text-center font-weight-bold mt-2">
                      {name}
                    </h6>
                  </fieldset>

                  <h4
                    className="text-center font-weight-bold mb-3"
                    style={{ color: "#474955" }}
                  >
                    Masukan Password Baru Anda
                  </h4>

                  <div class="input-group mb-2">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control px-2 form-control-auth"
                      name="password"
                      value={password}
                      onChange={(e) => this.handleChange(e)}
                      disabled={spinner}
                      placeholder="Password Baru"
                    />
                    <div
                      class="input-group-append bg-white pointer"
                      onClick={() =>
                        this.setState({
                          showPassword: !showPassword,
                        })
                      }
                    >
                      <span class="input-group-text bg-white fs-16px  px-1-2">
                        {showPassword ? (
                        <EyeIcon/>
                      ) : (
                        <EyeSlashIcon/>
                      )}
                      </span>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control px-2 form-control-auth"
                      name="confirmed"
                      value={confirmed}
                      onChange={(e) => this.handleChange(e)}
                      disabled={spinner}
                      placeholder="Ulangi Password Baru"
                    />
                    <div
                      class="input-group-append bg-white pointer"
                      onClick={() =>
                        this.setState({
                          showPassword: !showPassword,
                        })
                      }
                    >
                      <span class="input-group-text bg-white fs-16px  px-1-2">
                        {showPassword ? (
                          <EyeIcon/>
                        ) : (
                          <EyeSlashIcon/>
                        )}
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-warning btn-block"
                    disabled={spinner}
                  >
                    {spinner === true ? (
                      <span>Menyimpan</span>
                    ) : (
                      <span>Atur Ulang</span>
                    )}
                  </button>
                </form>
              </div>
              <div class="p-2 col-md-12"></div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
