import React from 'react';
import Breadcrumbs from './Breadcrumbs';

export default function ContentHeader(props){
    let pathlist = []
    if(props.subtitle)
        pathlist.push({ path: props.path, name:props.subtitle })
    pathlist.push({ path:'active', name:props.title || '' },)
    return <div className="content-header row " style={{ marginBottom: '0.5rem' }}>
                <div className="content-header-left col-md-6 col-12 breadcrumb-new">
                    <h4 className='font-weight-bold mb-0'>{props.title || ''}</h4>
                    <Breadcrumbs  data={pathlist}/>
                </div>
                <div className="col-md-6 col-12 breadcrumb-new text-right">
                    {props.children || ''}
                </div>
            </div>
}