import React from 'react';
import IllustrationImg from "./../../assets/eo-ilustration.png";

export default function AuthIlustration(){
    return <div 
      class="d-flex flex-column justify-content-center mx-auto p-3" 
      style={{ minHeight:'100vH',background:"linear-gradient(249.77deg,#58b9ff -1.99%,#007dff 43.52%,#065abd 95.86%)" }}>
      <img
        className="mb-3 img-fluid mx-auto"
        src={IllustrationImg}
        alt="First slide"
        style={{
          maxWidth:"440px"
        }}
      />
      <div className='d-flex flex-column mx-auto text-center text-white' style={{
          maxWidth:"440px"
        }}>
      <h2 className='font-weight-bold text-white'>Tingkatkan Target Pasar Anda Melalui Sponsor Event</h2>
      <label>MySponsor menghubungkan antara dua belah pihak yang saling membutuhkan. Sponsorship sudah menjadi tren dunia , mereka telah berkembang sangat cepat dan menjadi aset yang kuat untuk perencana acara.</label>

    </div>
  </div> 
            
}