
import React, { Component, Fragment } from 'react';

export default class NotFound extends Component {

  render() {
    return (
      <Fragment>
        <div className="row">
          <div className="col">
            <div className="content-body">
              <div className="col-12 d-flex align-items-center justify-content-center">
                <div className="col-md-4 col-10 p-0">
                  <div className="card-header bg-transparent border-0">
                    <h2 className="text-center mb-2" style={{fontSize : '10rem'}}>404</h2>
                    <h3 className="text-uppercase text-center">Halaman Tidak Tersedia !</h3>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </Fragment>
    );
  }
}

