import React, { Component } from "react";
import DateRangeInput from "../../components/plugin/filter/DateRangeInput";

//filter
import PencarianInput from "../../components/plugin/filter/PencarianInput";

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      start_date:"",
      end_date:"",
      status:"",

    };
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () =>
      this.props.onChangeValue(this.state)
    );
  }

  handleChangeDate(value){
    this.setState({ 
      start_date: value.start_date || '',
      end_date: value.end_date || ''
    }, () =>
      this.props.onChangeValue(this.state)
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="form-group col-lg-3 col-md-4 col-sm-6 col-xs-12">
            <PencarianInput
              name="search"
              onChangeValue={(e) => this.handleChange(e)}
            />
          </div>
          <div className="form-group col-lg-4 col-md-5 col-sm-6 col-xs-12">
            <DateRangeInput onChange={(e)=>this.handleChangeDate(e.target.value)}/>
          </div>
          <div className="col">
            <ul className="list-inline m-0 d-flex mb-1">
              <li className="list-inline-item">
                <button type="button" onClick={() => this.handleChange({target:{name:'status',value:''}})} className={"btn btn-icon action-icon bg-white d-flex align-items-center " +(this.state.status === '' ? 'bg-info text-white':'') } style={{ border: '1px solid #acafb3' }}>
                  <div className="mr-1">Semua</div>
                </button>
              </li>
              <li className="list-inline-item">
                <button type="button" onClick={() => this.handleChange({target:{name:'status',value:'FOLLOW UP'}})} className={"btn btn-icon action-icon bg-white d-flex align-items-center " +(this.state.status === 'FOLLOW UP' ? 'bg-info text-white':'') } style={{ border: '1px solid #acafb3' }}>
                  <div className="mr-1">Follow Up</div>
                </button>
              </li>
              <li className="list-inline-item">
                <button type="button" onClick={() => this.handleChange({target:{name:'status',value:'REJECTED'}})} className={"btn btn-icon action-icon bg-white d-flex align-items-center " +(this.state.status === 'REJECTED' ? 'bg-info text-white':'') } style={{ border: '1px solid #acafb3' }}>
                  <div className="mr-1">Rejected</div>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
