import React, { Component, Fragment } from "react";

//table
import Filter from "./Filter";
import EmptyPage from "../../components/plugin/EmptyPage";

//// MODEL
import { getIndex } from "../../utils/api";
import ContentHeader from "../../components/mikro/ContentHeader";
import ImageCover from "../../components/mikro/ImageCover";
import ReactPaginate from "react-paginate";
import {Redirect} from "react-router-dom"
import { PDFIcon } from "../../components/mikro/Icon";
import FileBadgeDownload from "../../components/mikro/FileBadgeDownload";
import ForbiddenCategoryNotExist from "../Others/ForbiddenCategoryNotExist";
export default class Kategori extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Proposal",
      redirect: "",

      filter: {
        paginate: true,
        search: "",
      },

      id: "",
      items: {
        data: [],
        total: 0,
        current_page: 0,
        last_page: 0,
        per_page: 10,
      },
      counterCategory:-1,
    };
  }
  componentDidMount(){
    this.getCheck()
  }
  getCheck(){
    getIndex("check").then((res) => {

      this.setState({
        counterCategory:res.data.count
      });
    });
  }
  getIndex() {
    getIndex("proposal", {
      ...this.state.filter,
      page: this.state.items.current_page,
    }).then((res) => {
      let items = {
        data: res.data || '',
        total: res.meta.total || 0,
        current_page: res.meta.current_page || 0,
        last_page: res.meta.last_page || 0,
        per_page: res.meta.per_page || 0,
      };
      this.setState({
        items: items,
      });

      window.scrollTo(0, 0);
    });
  }

  handleFilter(e) {
    let { filter } = this.state;
    this.setState({ filter:{...filter,...e} }, () => this.state.counterCategory > 0 && this.getIndex());
  }

  handlePageClick = async (data) => {
    const page = data.selected >= 0 ? data.selected + 1 : 0;
    this.setState(
      {
        items: {
          ...this.state.items,
          current_page: page,
        },
      },
      () => this.getIndex()
    );
  };


  handleDetail(id) {
    this.setState(
      {
        redirect: "/app/proposal/"+id,
      },
      () => this.getIndex()
    );
  }

  handleClickFile(e,url){
    e.preventDefault();
    e.stopPropagation();
    window.open(url, '_blank', 'noreferrer');
  }

  render() {
    const { items, title, redirect, counterCategory } = this.state;
    if(counterCategory === -1 ) return <div></div>
    if(counterCategory === 0 ) return <ForbiddenCategoryNotExist />
    if(redirect !== '')
      return <Redirect to={redirect}/>
    return (
      <Fragment>
        <ContentHeader title={title} />
        <div className="content-header row mt-2">
          <div className="content-header-left col-12 breadcrumb-new">
            <Filter onChangeValue={(e) => this.handleFilter(e)} />
          </div>
        </div>
        <div className="content-body">
          <div className="row">
            <div className="col">
              {parseInt(this.state.items.total) > 0 ? (
                <div>
                  <div className="table-responsive">
                    <table className="table table-hover table-hover">
                      <thead className="bg-f4">
                        <tr>
                          <th>No</th>
                          <th>Nama Kegiatan/Proposal</th>
                          <th>Tanggal Kegiatan</th>
                          <th>Lokasi</th>
                          <th>Target Peserta</th>
                          <th>File/Attachment</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.items.data.map((d, index) => (
                          <tr
                            className="pointer"
                            key={index}
                            onClick={() => this.handleDetail(d.id)}
                          >
                            <td className="align-middle">{ ((items.current_page - 1) * items.per_page) + index + 1}</td>
                            <td className="align-middle">{ d.title || '' }</td>
                            <td className="align-middle">{ d.event_date || '' }</td>
                            <td className="align-middle">{ d.event_location || '' }</td>
                            <td className="align-middle text-right">{ d.target_participants || '' }</td>
                            <td className="align-middle">
                              <FileBadgeDownload onClick={(e)=> this.handleClickFile(e,d.file_url)} />
                            </td>
                            <td className={"align-middle font-weight-bold "+(d.approval_status !== 'FOLLOW UP' ? "text-danger " : "text-success")}>{ d.approval_status || '' }</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-center w-100">
                    <ReactPaginate
                      previousLabel={"Sebelumnya"}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextLabel={"Selanjutnya"}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel={"..."}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      marginPagesDisplayed={2}
                      onPageChange={this.handlePageClick}
                      pageRangeDisplayed={items.per_page}
                      pageCount={items.last_page}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                    />
                  </div>
                </div>
              ) : (
                <div className="mt-5">
                  <EmptyPage text={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
