import React, { Component } from "react";
import { SearchIcon } from "../../mikro/Icon";
export default class Pencarian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      typing: false,
      typingTimeout: 0,
    };

    this.changeName = this.changeName.bind(this);
  }
  sendToParent = () => {
    this.props.onChangeValue({
      target: { value: this.state.name, name: this.props.name || "pencarian" },
    });
  };

  changeName = (event) => {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      name: event.target.value,
      typing: false,
      typingTimeout: setTimeout(function () {
        self.sendToParent(self.state.name);
      }, 500),
    });
  };

  render() {
    let { name } = this.state;
    return (
      <>
        {this.props.label && <label>{this.props.label}</label>}
        <div className="form-group position-relative has-icon-left">
          <div class="form-control-position" style={{
            lineHeight:'3.34'
          }}>
          <SearchIcon/>
          </div>
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            style={{ paddingLeft:"2.2rem" }}
            name="pencarian"
            placeholder={this.props.placeholder || "Pencarian"}
            value={name}
            onChange={(e) => this.changeName(e)}
          />

          {name !== "" ? (
            <span
              style={{
                padding: "9px",
                zIndex: "100",
                right: "5px",
                top: "0px",
                cursor: "pointer",
                lineHeight: '1.72',
              }}
              className="text-danger position-absolute font-weight-bold font-medium-2"
              onClick={() => this.changeName({ target: { value: "" } })}
            >
              <i className="ft-x-circle"></i>
            </span>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
