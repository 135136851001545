import React from "react"
import { Link } from "react-router-dom"

export default function Breadcrumbs(props){
    return <div class="breadcrumb-wrapper">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><Link to="/app/beranda">Beranda</Link></li>
                {
                    props.data.map((item, index) => (
                        <li class={"breadcrumb-item " + (item.path == 'active' ? item.path : '') } key={index}>{ item.path != 'active' ? <Link to={item.path}>{item.name}</Link> : item.name }</li>
                    ))
                }
            </ol>
        </div>
}