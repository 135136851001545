import React, { useEffect, useState } from "react";
import AssignList from "../plugin/form/AssignList";
const WilayahSelect = (props) => {
  const [show, setShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(props.value);
  useEffect(() => {
    if (props.value !== selectedValue) setSelectedValue(props.value);
  }, [props.value]);
  return (
    <div>
      <input
        type="text"
        className="form-control bg-white pointer"
        placeholder={props.placeholder || "Mohon masukkan"}
        value={selectedValue || props.defaultShowedValue}
        onClick={() => setShow(true)}
        readOnly={true}
      />
      <AssignList
        url="select-operations"
        title={"Wilayah"}
        openModal={show}
        onClose={() => setShow(false)}
        onChange={(e) => {
          setSelectedValue(e.data.label);
          props.onChange({
            target: { name: props.name, value: e.data.value },
          });
        }}
      />
    </div>
  );
};

export default WilayahSelect;
