import React from "react";
import { BASE_URL } from "../../enums/url";
import LogoColor from "./../../assets/logo/logo-color.png";

export default function LogiLink({ width }) {
  return (
    <a href={BASE_URL}>
      <img src={LogoColor} width={width || "100px"} alt="Logo" />
    </a>
  );
}
