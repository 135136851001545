import React from "react";
function Button(props) {
  return (
    <button
      type="button"
      className={
        "btn " + props.buttonType + " " + props.width + "  border-0 rounded-0"
      }
      onClick={(e) => props.onClick(e)}
    >
      <i className={props.iconClass}></i> {props.text}
    </button>
  );
}

function OptionButton(props) {
  if (props.type === "add")
    return (
      <div className="d-flex SlideModal_footer_button">
        <Button
          text={"Batal"}
          width={"w-25"}
          iconClass={"ft-x"}
          buttonType={"btn-outline-danger"}
          onClick={(e) => props.reset(e)}
        />
        <Button
          text={"Simpan"}
          width={"w-75"}
          iconClass={"la la-check-square-o"}
          buttonType={"btn-info"}
          onClick={(e) => props.store(e)}
        />
      </div>
    );
  else if (props.type === "edit")
    return (
      <div className="d-flex SlideModal_footer_button">
        <Button
          text={"Batal"}
          width={"w-25"}
          iconClass={"ft-x"}
          buttonType={"btn-outline-danger"}
          onClick={(e) => props.reset(e)}
        />
        <Button
          text={"Hapus"}
          width={"w-25"}
          iconClass={"ft-trash"}
          buttonType={"btn-outline-danger"}
          onClick={(e) => props.delete(e)}
        />
        <Button
          text={"Simpan"}
          width={"w-50"}
          iconClass={"la la-check-square-o"}
          buttonType={"btn-info"}
          onClick={(e) => props.store(e)}
        />
      </div>
    );
  else if (props.type === "simpan_selesai")
    return (
      <div className="d-flex SlideModal_footer_button">
        <Button
          text={"Simpan"}
          width={"w-25"}
          iconClass={"ft-save"}
          buttonType={"btn-outline-warning"}
          onClick={(e) => props.store2(e, "simpan")}
        />
        <Button
          text={"Simpan & Selesai"}
          width={"w-75"}
          iconClass={"la la-check-square-o"}
          buttonType={"btn-info"}
          onClick={(e) => props.store2(e, "selesai")}
        />
      </div>
    );
  else if (props.type === "edit_selesai")
    return (
      <div className="d-flex SlideModal_footer_button">
        <Button
          text={"Edit"}
          width={"w-25"}
          iconClass={"ft-edit"}
          buttonType={"btn-outline-warning"}
          onClick={(e) => props.edit(e)}
        />
        <Button
          text={"Selesai"}
          width={"w-75"}
          iconClass={"la la-check-square-o"}
          buttonType={"btn-info"}
          onClick={(e) => props.store(e)}
        />
      </div>
    );
  else if (props.type === "draft_publish")
    return (
      <div className="d-flex SlideModal_footer_button">
        <Button
          text={"Batal"}
          width={"w-25"}
          iconClass={"ft-x"}
          buttonType={"btn-outline-danger"}
          onClick={(e) => props.reset(e)}
        />
        <Button
          text={"Draft"}
          width={"w-25"}
          iconClass={"ft-save"}
          buttonType={"btn-outline-secondary"}
          onClick={(e) => props.store('DRAFT')}
        />
        <Button
          text={"Simpan & Publikasi"}
          width={"w-50"}
          iconClass={"la la-check-square-o"}
          buttonType={"btn-info"}
          onClick={(e) => props.store('PUBLISHED')}
        />
      </div>
    );
  else if (props.type === "delete_draft_publish")
    return (
      <div className="d-flex SlideModal_footer_button">
        <Button
          text={"Hapus"}
          width={"w-25"}
          iconClass={"ft-trash"}
          buttonType={"btn-outline-danger"}
          onClick={(e) => props.delete(e)}
        />
        <Button
          text={"Draft"}
          width={"w-25"}
          iconClass={"ft-save"}
          buttonType={"btn-outline-secondary"}
          onClick={(e) => props.store("DRAFT")}
        />
        <Button
          text={"Simpan & Publikasi"}
          width={"w-50"}
          iconClass={"la la-check-square-o"}
          buttonType={"btn-info"}
          onClick={(e) => props.store("PUBLISHED")}
        />
      </div>
    );
}
export default function BtnSaveFooterForm(props) {
  return (
    <OptionButton
      type={props.type}
      reset={(e) => props.reset(e)}
      store={(e) => props.store(e)}
      store2={(e, a) => props.store(e, a)}
      edit={(e) => props.edit(e)}
      delete={(e) => props.delete(e)}
    />
  );
}
