import React from 'react';
import { PDFIcon } from './Icon';
const FileBadgeDownload = (props) => {
    return (
        <div className="btn btn-outline-info btn-sm d-flex rounded-pill align-items-center flex-row fs-14px"
            {...props}
        >
            <span ><PDFIcon />&nbsp;</span>
            <span className='nowrap'>File Proposal.pdf</span>
        </div>
    );
};

export default FileBadgeDownload;