import React, { Component, Fragment } from "react";

import {
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  WaIcon,
} from "../../components/mikro/Icon";
import ImageUploadPreview from "../../components/plugin/form/ImageUploadPreview";

import { getIndex, addItem } from "../../utils/api";
import ContentHeader from "../../components/mikro/ContentHeader";
import toastr from "../../utils/toastr";

import {minLength} from './../../utils/Validation'
import WilayaSelect from "../../components/makro/WilayahSelect";
import { updateStatusProfilPerusahaan, updateNamaProfilPerusahaan } from "../../utils/AuthUser";


export default class InfoKontak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      corporate_name:'',
      corporate_telephone:'',
      corporate_email:'',
      corporate_address:'',
      corporate_website:'',
      corporate_operation_id:'',
      corporate_operation_label:'',

      corporate_logo:"",
      image:null,
      errors:null,

      isLoading:false,
      isSaving:false,
    };
  }
  componentDidMount() {
    this.getProfil()
  }
  
  getProfil(){
    this.setState({
      isLoading:true,
    },()=>{
      getIndex("corporate-profile").then((res) => {
        this.setState({
          corporate_logo: res && res.data && (res.data.corporate_logo || ''),
          corporate_name: res && res.data && (res.data.corporate_name || ''),
          corporate_telephone: res && res.data && (res.data.corporate_telephone || ''),
          corporate_email: res && res.data && (res.data.corporate_email || ''),
          corporate_address: res && res.data && (res.data.corporate_address || ''),
          corporate_website: res && res.data && (res.data.corporate_website || ''),
          corporate_operation_id: res && res.data && (res.data.corporate_operation_id || ''),
          corporate_operation_label: res && res.data && (res.data.corporate_operation_label || ''),
          isLoading:false
        });
      });
    })
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    let errors = null
    const {
      corporate_name,
      corporate_telephone,
      corporate_email,
      corporate_address,
      corporate_website,
      corporate_operation_id,
    } = this.state
    if (!minLength(corporate_name,5))  errors = {...errors,corporate_name : "Nama perusahaan minimal 2 karakter"}
    if (!minLength(corporate_telephone,8))  errors = {...errors,corporate_telephone : "Nomor telpon perusahaan minimal 3 karakter"}
    if (!minLength(corporate_email,5))  errors = {...errors,corporate_email : "Email perusahaan minimal 5 karakter"}
    if (!minLength(corporate_address,3))  errors = {...errors,corporate_address : "Alamat perusahaan minimal 3 karakter"}

    this.setState({ errors })

    const data = new FormData() 
    data.append('corporate_logo', this.state.image)

    data.append('corporate_name',corporate_name);
    data.append('corporate_telephone',corporate_telephone);
    data.append('corporate_email',corporate_email);
    data.append('corporate_address',corporate_address);
    data.append('corporate_website',corporate_website);
    data.append('corporate_operation_id',corporate_operation_id);

    if (errors === null){
      this.setState({
        isSaving: true
      },()=>{

        addItem("corporate-profile", data).then((res) => {
          toastr(res.status, res.message);
          
          if(res.code === 200){
            updateStatusProfilPerusahaan();
            updateNamaProfilPerusahaan(corporate_name);
          }
          setTimeout(()=>{
            this.setState({
              isSaving: false
            })

          },1000)
        });
      })
    }
  }

  render() {
    const {
      corporate_name,
      corporate_telephone,
      corporate_email,
      corporate_address,
      corporate_website,
      corporate_operation_label,
      errors,
      isLoading,
      isSaving
    } = this.state;
    return (
      <Fragment>
        <ContentHeader
          title="Profil Perusahaan"
        />
        <div className="content-body">
          <form onSubmit={(e) => this.handleSubmit(e)}>
           
            <div className="row justify-content-center p-1">
              <div className="col-10  mb-1">
                <div className="mb-3 text-center">
                  <ImageUploadPreview
                    value={this.state.corporate_logo}
                    height="150px"
                    width="170px"
                    onChangeValue={(value) => this.setState({ image: value })}
                  />
                  <small className="mt-1 ">&#128712;&nbsp;Upload Logo Disini</small>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-5">
                    <label>Nama Perusahaan*</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Masukkan nama perusahaan"
                      name="corporate_name"
                      value={corporate_name}
                      onChange={(e) => this.handleChange(e)}
                      disabled={isLoading  || isSaving}
                    />
                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                  
                  <div className="col-md-5">
                    <label>Nomor Telpon*</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Masukkan nomor telpon"
                      name="corporate_telephone"
                      value={corporate_telephone}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      disabled={isLoading  || isSaving}
                    />
                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center mb-2">
                  
                  <div className="col-md-5">
                    <label>Email*</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Masukkan email"
                      name="corporate_email"
                      value={corporate_email}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      disabled={isLoading  || isSaving}
                    />
                    {errors && errors.nama_pic && (
                      <div className="invalid-feedback d-block">
                        {errors.nama_pic}
                      </div>
                    )}
                  </div>
                  <div className="col-md-5">
                    <label>Website</label>
                    <input
                      type="url"
                      className="form-control"
                      placeholder="Masukkan alamat domain website"
                      name="corporate_website"
                      value={corporate_website}
                      onChange={(e) => this.handleChange(e)}
                      disabled={isLoading  || isSaving}
                    />
                  </div>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-10">
                    <label>Wilayah Operasional</label>
                    <WilayaSelect 
                      placeholder="Pilih wilayah operasional"
                      name="corporate_operation_id"
                      defaultShowedValue={corporate_operation_label}
                      onChange={(e) => this.handleChange(e)}/>
                  </div>
                </div>
                <div className="row justify-content-center mb-2">
                  <div className="col-md-10">
                    <label>Alamat*</label>
                    <textarea
                      className="form-control"
                      placeholder="Masukkan alamat perusahaan"
                      name="corporate_address"
                      value={corporate_address}
                      onChange={(e) => this.handleChange(e)}
                      required={true}
                      disabled={isLoading  || isSaving}
                    ></textarea>
                    {errors && errors.corporate_address && (
                      <div className="invalid-feedback d-block">
                        {errors.corporate_address}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
           

            <div className="row p-1">
              <div className="col-12">
                <div className="form-group text-center">
                  <button 
                    type="submit" 
                    className="btn btn-info px-5 py-1" 
                    style={{ minWidth:'40%'}}
                    disabled={isLoading  || isSaving}
                    >
                    { isSaving ? "Menyimpan...":"Simpan"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Fragment>
    );
  }
}
