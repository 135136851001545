export const maxNumber = (value, max) => {
  return !isNaN(Number(value)) && value <= max;
};

export const minNumber = (value, min) => {
  return !isNaN(Number(value)) && value >= min;
};

export const minLength = (value, min) => {
  return value.length >= min;
};

export const maxLength = (value, max) => {
  return value.length <= max;
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};