import React, {  Fragment, useEffect, useState } from "react";
import {  Redirect,    useParams } from 'react-router-dom';


import { PDFXLIcon, DateIcon, DotIcon,TrashIcon, PencilSquareIcon, UserSettingIcon, CompanyIcon} from "../../components/mikro/Icon";
import { addItem, getIndex, updateItem } from "../../utils/api";
import { deleteSaveUser} from "../../utils/AuthUser";
import toastr from "../../utils/toastr";

export default function ProposalDetail () {
    const {id} = useParams();
    const [data, set_data] = useState(null);
    const [isOpenPanel, set_isOpenPanel] = useState(null);
    const [redirect, set_redirect] = useState("");
    useEffect(()=>{
      getData()
    },[])

    const getData = () =>{
      getIndex("proposal/"+id).then((res) => {
        console.log(res)
        // if(res.code === 200)
          set_data(res && res.data && (res.data || null));
        // else if(res.code === 400){
        //   toastr(res.status,res.message)
        //   setTimeout(()=>{
        //     set_redirect("/app/proposal")
        //   },500)
        // }
      });
    }

    const handleStatus = (status) => {
      updateItem("proposal/"+id+"/follow-up-status", {
        status: status,
      }).then((res) => {
        if(res.code === 200)
          getData();
        toastr(res.status,res.message)
      }).catch((err)=>{
        if(err.code === 401){
          deleteSaveUser();
          setTimeout(()=>{
            set_redirect("/")
          },500)
        }
      });
    }
    
    if(redirect !== "")
    return <Redirect to={redirect}/>
    return (
      <Fragment>
        {data && <div className="card mb-2">
          <div className="card-header px-3 pt-3 pb-1 d-flex justify-content-between">
            {data && data.title && <h3 className="font-weight-bold m-0">{data.title }</h3>}
          </div>
          <div className="card-body px-3 pb-3">
            {data && data.published_date && <p className="d-flex text-muted align-items-center mb-2 fs-16px">
              <DateIcon/><div>&nbsp;&nbsp;Dipublikasikan: {data.published_date}</div>
            </p>}
            {data && data.message && 
            <div className="row mb-2">
              <div className="col-md-12">
                <h5 className="mb-2">Deskripsi/Pesan ke Sponsor</h5>
                <p className="mb-2 fs-14px" style={{ lineHeight: '1.8em'}}>
                <div dangerouslySetInnerHTML={ { __html: data.message } }></div></p>
              </div>
            </div>}
            { data && data.approval_status && 
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="mb-2">Status Follow Up</h5>
                <div className={"fs-14px d-flex align-items-center " + (data.approval_status !== 'FOLLOW UP' ? 'text-danger' :'text-success'  )}>
                  <DotIcon width="24" height="24"/> <span>{data.approval_status || ''}</span>
                </div>
              </div>
            </div>}
         
            { data && data.event_date && 
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="mb-2">Tanggal Kegiatan</h5>
                <p>{data.event_date}</p>
              </div>
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="mb-2">Jam Kegiatan</h5>
                <p>{data.event_time}</p>
              </div>
            </div>}

            { data && (data.event_location || data.target_participants) &&
            <div className="row mb-2">
              {data && data.event_location && <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="mb-2">Lokasi Kegiatan</h5>
                <p>{data.event_location}</p>
              </div>}
              { data && data.target_participants && <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="mb-2">Target Peserta</h5>
                <p>{data.target_participants}</p>
              </div>}
            </div>}

            { data && data.file_url && data.file_name && 
            <div className="row mb-2">
              <div className="col-xl-4 col-lg-6  col-md-12">
                <h5 className="mb-2">Lampiran</h5>

                <a 
                  href={data.file_url} 
                  className=" card p-1 d-flex flex-row align-items-center"
                >
                  <div className="mr-05">
                    <PDFXLIcon width="50" height="50" />
                  </div>
                  <div className="position-relative">
                    <p>{data.file_name || ''}</p>
                  </div>
                </a>
                <small className="mt-1 text-muted">&#128712;&nbsp;Klik untuk membuka file proposal</small>
              </div>
            </div>}

            {data && data.categories && <h5 className="font-weight-bold mb-2">{data.categories.length} Jenis Kegiatan</h5>}
            {data && data.categories && <p>{data.categories.map((category,i)=>(
              <div 
                className="btn btn-outline-danger rounded mr-05" 
                style={{padding:'.4rem 1rem'}}
                key={category}>{category}</div>
              ))}
            </p>}
          </div>
        </div>}
        {data && <div className="card mb-2">
          <div className="card-header">
            <h4 className="card-title mb-0 font-weight-bold">Jenis dan Keuntungan Sponsorship</h4>
          </div>
          <div className="card-content">
            <div className="table-responsive">
              <table id="recent-orders" className="table table-hover table-xl mb-0">
                <thead>
                  <tr>
                    <th className="border-top-0" style={{width:"20%"}}>Jenis Sponsorship</th>
                    <th className="border-top-0" style={{width:"30%"}}>Supporting</th>
                    <th className="border-top-0" style={{width:"50%"}}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  { data && (data.support_main_sponsor_need || data.support_main_sponsor_value) && <tr>
                    <td>Sponsor Utama</td>
                    <td>{data.support_main_sponsor_need || ''}</td>
                    <td>{data.support_main_sponsor_value || ''}</td>
                  </tr>}
                  { data && (data.support_co_sponsor_need || data.support_co_sponsor_value) && <tr>
                    <td>Sponsor Pendamping</td>
                    <td>{data.support_co_sponsor_need || ''}</td>
                    <td>{data.support_co_sponsor_value || ''}</td>
                  </tr>}
                  { data && (data.support_other_sponsor_need || data.support_other_sponsor_value) && <tr>
                    <td>Sponsor Lainnya</td>
                    <td>{data.support_other_sponsor_need || ''}</td>
                    <td>{data.support_other_sponsor_value || ''}</td>
                  </tr>}
                </tbody>
              </table>
            </div>
          </div>
        </div>}

         {data && data.eo && 
        <div className="card mb-2">
          <div className="card-header">
            <h4 className="card-title mb-0 font-weight-bold">Info Event Organizer</h4>
          </div>
          <div className="card-body">
            <div className="col-12">
              <h5 className="mb-1">
                <CompanyIcon/>&nbsp;&nbsp;Info EO
              </h5>
              <table className="table mb-2">
                <tbody>
                  <tr>
                    <td width="17%">Nama EO:</td>
                    <td className="users-view-username">{data && data.eo && (data.eo.eo_name || '')}</td>
                  </tr>
                  <tr>
                    <td width="17%">Nomor Telpon:</td>
                    <td className="users-view-name">{data && data.eo && (data.eo.eo_telephone || '')}</td>
                  </tr>
                  <tr>
                    <td width="17%">E-mail:</td>
                    <td className="users-view-email">{data && data.eo && (data.eo.eo_email || '')}</td>
                  </tr>
                  <tr>
                    <td width="17%">Alamat :</td>
                    <td>{data && data.eo && (data.eo.eo_operation_label || '')} {data && data.eo && (data.eo.eo_address || '')}</td>
                  </tr>
                </tbody>
              </table>
              
              <h5 className="mb-1"><UserSettingIcon/>&nbsp;&nbsp;Info PIC</h5>
              <table className="table  mb-0 mb-1">
                <tbody>
                  <tr>
                    <td width="17%">Nama:</td>
                    <td>{data && data.eo && (data.eo.pic_name || '')}</td>
                  </tr>
                  <tr>
                    <td width="17%">Nomor HP:</td>
                    <td>{data && data.eo && (data.eo.pic_phone_number || '')}</td>
                  </tr>
                  <tr>
                    <td width="17%">E-mail:</td>
                    <td>{data && data.eo && (data.eo.pic_email || '')}</td>
                  </tr>
                  <tr>
                    <td width="17%">Posisi di EO:</td>
                    <td>{data && data.eo && (data.eo.pic_job || '')}</td>
                  </tr>
                  
                </tbody>
              </table>
            </div>
          </div>
        </div>}

        { data && data.approval_status === 'WAITING CONFIRMATION' && <div className="card mb-2">
          <div className="card-header">
            <h4 className="card-title mb-0 font-weight-bold"><span className="text-success ">Follow Up</span> atau <span className="text-danger ">Reject</span> ?</h4>
          </div>
          <div className="card-body">
            <p className="fs-14px">Event Organizer membutuhkan informasi <b>Follow Up/Reject</b> dari Anda. Dengan menekan Follow Up pada tombol dibawah berarti Anda menyetujui untuk menambahkan perusahaan Anda kedalam daftar sponsor pada proposal ini. Namun jika menekan tombol Reject berarti Anda menolak proposal ini.</p>
          </div>  
          <div className="card-footer">
            <div className="d-flex flex-row w-100 justify-content-center">
              <div className="btn btn-outline-danger mr-2 px-3" onClick={()=>handleStatus('REJECTED')}>Reject</div>
              <div className="btn btn-success px-5" onClick={()=>handleStatus('FOLLOW UP')}>Follow Up</div>
            </div>
          </div>  
        </div>}
      </Fragment>
    );
}
