import React from 'react';
import Switch from 'react-switch';

const StatusSwitch = ({status, onChange}) => {
    return (
        <div>
            <label>Status Aktif</label>
            <div class="card mb-1">
                <div class="card-body d-flex justify-content-between py-1">
                    <div className="flex-fill align-self-center ">
                        <h6 className="m-0 font-weight-600">
                            {status}
                        </h6>
                    </div>
                    <div className="text-right align-middle" style={{ marginTop: "5px" }}>
                        <Switch
                            onChange={() => onChange(status === 'ACTIVE' ? 'INACTIVE':'ACTIVE')}
                            height={24}
                            width={50}
                            checked={status === 'ACTIVE'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatusSwitch;