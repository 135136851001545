import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import { DateIcon } from '../../mikro/Icon';
import { indonesianDate } from '../../../utils/DateFormat';


export default class DateRangeInput extends React.Component {

  constructor(props) {
    super(props);

    this.handleApply = this.handleApply.bind(this);

    this.state = {
      startDate: moment(new Date()),
      endDate: moment(new Date()),
      // ranges: {
      //   'Today': [moment(), moment()],
      //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      //   'This Month': [moment().startOf('month'), moment().endOf('month')],
      //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      // },
    };
  }
  componentDidMount(){
    let startDate = (new Date())
    let endDate = (new Date())
    
    this.props.onChange({ 
      target:{
        value:{
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
        },
        name: this.props.name || 'tanggal'
      }
    });
  }

  async handleApply(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
    });


    this.props.onChange({ 
      target:{
        value:{
          start_date: picker.startDate.format('YYYY-MM-DD'),
          end_date: picker.endDate.format('YYYY-MM-DD'),
        },
        name: this.props.name || 'tanggal'
      }
    });
  }

  render() {
    let start = this.state.startDate.format('YYYY-MM-DD');
    let end = this.state.endDate.format('YYYY-MM-DD');
    let label = indonesianDate(start) + ' - ' + indonesianDate(end);
    if (start === end) {
      label = indonesianDate(start);
    }
    

    return (
      <React.Fragment>
          <DateRangePicker
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onApply={this.handleApply}
            autoApply={true}
            containerStyles={{display:'inherit'}}	
          >
          <div class="input-group ">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01"><DateIcon /></label>
            </div>
              <input type="text" className="form-control text-center" name={ this.props.name || 'tanggal' }  value={label} onChange={()=>{return;}}/>
          </div>
          </DateRangePicker>
        </React.Fragment>
    );
  }

}
