import React from "react";
import { Link } from "react-router-dom";

const Item = (props) => {
  return (
    <Link
      className={
        window.location.href.indexOf(props.link) > -1
          ? "ember-view ember-view-active"
          : "ember-view"
      }
      onClick={() => props.handleOutsideClick()}
      to={props.url + "/" + props.link}
    >
      <div className="d-flex flex-row" style={{ justifyContent: "flex-start" }}>
        <div className="icon">{props.icon}</div>
        <div>{props.title}</div>
      </div>
    </Link>
  );
};

export default Item;
